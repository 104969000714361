<template>
	<modal class="NoxModalLandings" name="NoxModalLandings" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="(noxType !== 'createEditorLanding' ? true : false)" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close" v-if="noxType !== 'createEditorLanding'"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'addLanding'" v-html="$store.getters.getLanguageText('5.25', 0)"></span>
					<span v-else-if="noxType == 'editLanding'" v-html="$store.getters.getLanguageText('5.25', 1)"></span>
					<span v-else-if="noxType == 'deleteLanding'" v-html="$store.getters.getLanguageText('5.25', 2)"></span>
					<span v-else-if="noxType == 'createEditorLanding'" v-html="$store.getters.getLanguageText('5.25', 0)"></span>
					<span v-else-if="noxType == 'addEditorLandingElement'" v-html="$store.getters.getLanguageText('5.25', 6)"></span>
					<span v-else-if="noxType == 'exitEditorLanding'" v-html="$store.getters.getLanguageText('5.25', 8)"></span>
				</div>
			</div>
			<div class="nox_modal_body">
				<form class="nox_modal_form">
					<div class="nox_modal_info">
						<div class="nox_modal_info_landings">
							<div v-if="['addLanding', 'editLanding', 'createEditorLanding'].includes(noxType)">
								<div class="nox_modal_info_row last">
									<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.25', 4)"></div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxName" :placeholder="$store.getters.getLanguageText('5.25', 5)" autocomplete="off">
										<div v-html="noxAlertName"></div>
									</div>
								</div>
							</div>
							<div v-else-if="noxType == 'deleteLanding'" v-html="$store.getters.getLanguageText('5.25', 3)"></div>
							<div v-else-if="noxType == 'addEditorLandingElement'">
								<div class="nox_modal_info_editor_landing_elements">
									<p v-html="$store.getters.getLanguageText('5.25', 7)"></p>
									<div class="nox_modal_info_editor_landing_elements_tools" v-if="noxAData.length">
										<div class="nox_modal_info_editor_landing_elements_tools_row" v-for="(value, index) in noxAData" :key="index">
											<div class="nox_modal_info_editor_landing_elements_tools_data" @click="$parent.noxEditorLandingPageElements.push(value);close();"><img :src="value.image_2"></div>
										</div>
									</div>
								</div>
							</div>
							<div v-else-if="noxType == 'exitEditorLanding'" v-html="$store.getters.getLanguageText('5.25', 9)"></div>
						</div>
					</div>
					<div class="nox_modal_alert" v-html="noxAlert"></div>
					<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
						<router-link to="/account/landings"><button type="button" class="nox_button common red" v-if="noxType == 'exitEditorLanding'" v-html="$store.getters.getLanguageText('1.1', 177)"></button></router-link>
						<button type="button" class="nox_button common" @click="close" v-if="!['createEditorLanding', 'exitEditorLanding'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
						<button type="submit" class="nox_button common green" @click="submit" v-if="['addLanding', 'createEditorLanding'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 5)"></button>
						<button type="submit" class="nox_button common green" @click="submit" v-else-if="noxType == 'editLanding'" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
						<button type="button" class="nox_button common red" @click="axios" v-else-if="noxType == 'deleteLanding'" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
						<button type="button" class="nox_button common green" @click="close" v-else-if="noxType == 'exitEditorLanding'" v-html="$store.getters.getLanguageText('1.1', 146)"></button>
					</div>
				</form>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertName: '',
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxAData: [],
			noxName: '',
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxAData = event.params.adata;
				this.noxName = '';
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 'editLanding') {
					this.noxName = this.$parent.noxLandingsData[this.noxIndex].name;
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertName = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.24', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.24', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.24', 2); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.24', 3); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.24', 4); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			submit: function(event) {
				event.preventDefault(); this.axios();
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (['addLanding', 'editLanding', 'createEditorLanding'].includes(_this.noxType)) {
						if (!_this.noxName) {
							_this.noxAlertName = _this.getError(3);
						}
						else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxName) || _this.noxName.length > 100) {
							_this.noxAlertName = _this.getError(4);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/account/landings';
							config.data = { id: _this.noxId, name: _this.noxName };
							config.method = ['addLanding', 'createEditorLanding'].includes(_this.noxType) ? 'post' : 'patch';
						}
					}
					else if (_this.noxType == 'deleteLanding') {
						config.url = '/v2/account/landings';
						config.data = { id: _this.noxId };
						config.method = 'delete';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.noxType == 'addLanding') {
									_this.$parent.noxLandingsData.push(data.data);
								}
								else if (_this.noxType == 'editLanding') {
									_this.$parent.noxLandingsData.splice(_this.noxIndex, 1, data.data);
								}
								else if (_this.noxType == 'deleteLanding') {
									_this.$parent.noxLandingsData.splice(_this.noxIndex, 1);
								}
								else if (_this.noxType == 'createEditorLanding') {
									if (Object.keys(data.data).length) {
										_this.$parent.noxEditorLandingData = data.data;
										_this.$parent.noxEditorLandingDataId = data.data.id;
										_this.$router.push({ path: '/editor/landing/' + data.data.id });
									}
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
									else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
									else if (data.response.data.error == 'NAME_IS_EMPTY') { _this.noxAlertName = _this.getError(3); }
									else if (data.response.data.error == 'NAME_NOT_VALID') { _this.noxAlertName = _this.getError(4); }
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(5); }
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalLandings');
			}
		}
	}
</script>
